<template>
  <div>
    <h1
      style="
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-decoration: underline;
      "
    >
      CarbonXo
    </h1>
  </div>

  <div id="nav" style="margin-bottom: 1.5rem">
    <router-link to="/"><button class="learn-more">සිංහල</button></router-link>
    <router-link to="/tamil" style="margin: 0px 10px"
      ><button class="learn-more">தமிழ்</button></router-link
    >
    <router-link to="/english"
      ><button class="learn-more">English</button></router-link
    >
  </div>
  <div>
    <router-view />
  </div>
</template>

<style>
@import "./assets/style.css";
</style>
