<template>
  <div class="container" style="padding-bottom: 40px">
    <div v-if="showModal" @close="showModal = false">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">
                  ඔබ විසින් පරිසරයට වෙත මුදා හැර ඇති කාබන් පියසටහන් ප්‍රමාණය
                </slot>
              </div>
              <div class="modal-body">
                <slot name="body">
                  {{ result.toFixed(3) }} tCo2e <br />
                  වසර 20 කදී අඔ ගස් {{ Math.round(amountTree) }} උරාගනු ලබන CO2
                  ප්‍රමාණය
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button
                    class="modal-default-button"
                    @click="close"
                    id="submit_button"
                  >
                    නැවත ගණනය
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Content here -->
    <div class="hello">
      <h4>
        පහත සඳහන් තොරතුරු පිරිවිම තුලින් අද දින ඔබගේ කාබන් පියසටහන් අගය ගණනය කල
        හැක.
      </h4>
      <form @submit.prevent="calculation" style="padding-top: 30px">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >ඔබ භාවිතා කරන ජංගම දුරකථන වර්ගය</label
          >
          <select
            class="form-select"
            aria-label="mobileType"
            v-model="formvalues.mobilephone"
          >
            <option value="1">ස්මාට් ජංගම දුරකථනයක්</option>
            <option value="2">ස්මාට් පහසුකම් නොමැති ජංගම දුරකථනයක්</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="charginghours" class="form-label"
            >අද දින ඔබගේ ජංගම දුරකථනය චාජ් කරන ලැබු පැය ගණන</label
          >
          <input
            type="number"
            step="any"
            class="form-control"
            id="charginghours"
            required
            v-model="formvalues.charginghours"
          />
        </div>
        <div class="mb-3">
          <label for="laptopChargingHours" class="form-label"
            >අද දින ඔබ ලැප්ටොප් පරිඝනකය භාවිතා කරන ලැබු පැය ගණනය</label
          >
          <input
            type="number"
            step="any"
            class="form-control"
            id="laptopChargingHours"
            required
            v-model="formvalues.laptopChargingHours"
          />
        </div>
        <div class="mb-3">
          <label for="transportmethod" class="form-label"
            >අද දින ඔබ ගමනාගමන කටයුතු සදහා වැඩි වශයෙන් භාවිතා කරන ලද ප්‍රවාහන
            මාධ්‍ය</label
          >
          <select
            class="form-select"
            aria-label="transportmethod"
            v-model="formvalues.transportmethod"
          >
            <option value="1">ඇවිදීම</option>
            <option value="2">යතුරු පැදිය</option>
            <option value="3">කාර් රථය</option>
            <option value="4">පොදු ප්‍රවාහන බස්රථ</option>
            <option value="5">පොදු ප්‍රවාහන දුම්රිය</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="transportDistance" class="form-label"
            >ඔබ අද දින යෙදුන ගමන් දුර ප්‍රවාහනය ඉහත දැක්වු ප්‍රවාහන මාධ්‍ය
            භාවිතයෙන් (km)
          </label>
          <input
            type="number"
            step="any"
            class="form-control"
            id="transportDistance"
            required
            v-model="formvalues.transportDistance"
          />
        </div>
      </form>
      <button
        type="submit"
        @click="calculation"
        class="btn btn-primary"
        id="submit_button"
      >
        ගණනය කරන්න
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sinhala",
  props: {
    msg: String,
  },
  data() {
    return {
      formvalues: {
        mobilephone: "1",
        charginghours: 0,
        laptopChargingHours: 0,
        transportmethod: "1",
        transportDistance: 0,
      },
      result: 0,
      showModal: false,
      carbSequValue: 0.457,
      amountTree: 0,
    };
  },
  methods: {
    calculation() {
      var totalEleEmission =
        this.formvalues["laptopChargingHours"] * 50 * 0.4694 +
        this.mobilephoneEmission();
      var totalTranEmission = this.transportEmission();
      this.result = (totalTranEmission + totalEleEmission) / 1000;
      this.amountTree = this.result / this.carbSequValue;
      this.showModal = true;
      this.formvalues = {
        mobilephone: "1",
        charginghours: 0,
        laptopChargingHours: 0,
        transportmethod: "1",
        transportDistance: 0,
      };
    },
    close() {
      this.showModal = false;
    },
    mobilephoneEmission() {
      var mEmission;
      if (this.formvalues["mobilephone"] == "1") {
        mEmission = 5 * 0.4694 * this.formvalues["charginghours"];
        return mEmission;
      } else {
        mEmission = 2.25 * 0.4694 * this.formvalues["charginghours"];
        return mEmission;
      }
    },
    transportEmission() {
      var trEmission = 0;
      if (this.formvalues["transportmethod"] == "1") {
        trEmission = 0 * this.formvalues["transportDistance"];
        return trEmission;
      } else if (this.formvalues["transportmethod"] == "2") {
        trEmission = 0.08773 * this.formvalues["transportDistance"];
        return trEmission;
      } else if (this.formvalues["transportmethod"] == "3") {
        trEmission = 0.13721 * this.formvalues["transportDistance"];
        return trEmission;
      } else if (this.formvalues["transportmethod"] == "4") {
        trEmission = 0.0237 * this.formvalues["transportDistance"];
        return trEmission;
      } else {
        trEmission = 0.00866 * this.formvalues["transportDistance"];
        return trEmission;
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  background: rgb(4 104 52);
  color: white;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-footer {
  justify-content: center;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
